import React, { useState } from 'react';
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { FaShareNodes } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function Icono() {
    const [isSocialActive, setIsSocialActive] = useState(false);

    const handleSocialButtonClick = () => {
      setIsSocialActive(!isSocialActive);
    };

    return (
        <div className="nav-social social">
            <Link onClick={handleSocialButtonClick}><FaShareNodes /></Link>
            <Link to="https://www.instagram.com/ypaez.code/" target="_blank" className={`social-movil ${isSocialActive ? 'activo' : ''}`}><FaInstagram /></Link>
            <Link to="https://www.facebook.com/yeifrypaez.code" target="_blank" className={`social-movil ${isSocialActive ? 'activo' : ''}`}><FaFacebookF /></Link>
            <Link to="https://www.tiktok.com/@yeifrypaez.code" target="_blank" className={`social-movil ${isSocialActive ? 'activo' : ''}`}><FaTiktok /></Link>
            <Link to="https://www.linkedin.com/in/yeifry-paez-a7096b257" target="_blank" className={`social-movil ${isSocialActive ? 'activo' : ''}`}><FaLinkedinIn /></Link>
        </div>
    );
  };
  
  export default Icono;