import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const initialValues = {
    nombre: '',
    email: '',
    mensaje: '',
    prioridad: '',
    acepto: false
  };

  const validationSchema = Yup.object().shape({
    nombre: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Ingrese solo letras')
      .trim()
      .required('El nombre es obligatorio'),
    email: Yup.string()
      .email('Ingrese un correo electrónico válido')
      .required('El correo electrónico es obligatorio'),
    mensaje: Yup.string().trim().required('El mensaje es obligatorio'),
    prioridad: Yup.string().required('Seleccione una prioridad'),
    acepto: Yup.boolean().oneOf([true], 'Debe aceptar el tratamiento de mis datos personales')

  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    const { nombre, email, mensaje } = values;

    setIsSpinnerVisible(true); // Mostrar el spinner

    try {
      const cleanedNombre = nombre.trim();
      const cleanedEmail = email.trim();
      const cleanedMensaje = mensaje.trim();

      await emailjs.send('service_mqcns45', 'template_1ca2wpw', {
        nombre: cleanedNombre,
        email: cleanedEmail,
        mensaje: cleanedMensaje,
        prioridad: values.prioridad,
        acepto: values.acepto,
        'g-recaptcha-response': recaptchaValue
      }, 'NJcS31QSLvhl0MBYJ');

      setIsFormSubmitted(true);
      setIsError(false);

      setTimeout(() => {
        setIsFormSubmitted(false);
      }, 3000); // Ocultar mensaje después de 3 segundos
    } catch (error) {
      setIsFormSubmitted(false);
      setIsError(true);

      setTimeout(() => {
        setIsError(false);
      }, 3000); // Ocultar mensaje después de 3 segundos
    } finally {
      setTimeout(() => {
        setIsSpinnerVisible(false); // Ocultar el spinner después de 3 segundos
      }, 3000);
    }

    resetForm();
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <>
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form className="yp-form-home">
        <div className="yp-home-s1">
          <div className="form-item">
            <Field type="text" id="nombre" name="nombre" placeholder="Nombre y Apellido" />
            <ErrorMessage name="nombre" component="div" className="error" />
          </div>
          <div className="form-item">
            <Field type="email" id="email" name="email" placeholder="Correo Electrónico" />
            <ErrorMessage name="email" component="div" className="error" />
          </div>
          <div className="form-item">
            <Field as="select" id="prioridad" name="prioridad">
              <option value="" disabled>
                Seleccione una opción
              </option>
              <option value="urgente">Urgente</option>
              <option value="asesoria">Asesoría</option>
              <option value="contratacion">Contratación</option>
            </Field>
            <ErrorMessage name="prioridad" component="div" className="error" />
          </div>
        </div>
        <div className="form-item">
          <Field as="textarea" id="mensaje" cols="30" rows="10" name="mensaje" placeholder="Mensaje"/>
          <ErrorMessage name="mensaje" component="div" className="error" />
        </div>
        <div>
        <div>
        <div className="form-checkbox">
            <Field type="checkbox" name="acepto" className="checkbox" />
            <p>
                He leído y acepto el  <Link to='/politica-privacidad' reloadDocument>tratamiento de mis datos personales</Link>
            </p>
          </div>
        </div>
            <ErrorMessage name="acepto" component="div" className="error" />
        </div>

        <ReCAPTCHA sitekey="6LfvTnEmAAAAAGj9DojTGL4hL3TEQNztYLkgrxiK" onChange={handleRecaptchaChange} />
        <button type="submit" className="btn-form" id="btn-sumit">
          Agendar
        </button>
        {isSpinnerVisible && (
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        )}  
        {isFormSubmitted && !isError && <p className='exito'>Mensaje enviado correctamente</p>}
        {isError && <p className='fallo'>Hubo un error al enviar el formulario. Por favor, inténtalo de nuevo más tarde</p>}
      </Form>
    </Formik>
    </>
  );
};

export default ContactForm;
