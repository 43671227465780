import React, { useState, useEffect } from 'react';
import './scroolup.css';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from "react-icons/fa";
import { FaAngleUp } from "react-icons/fa";

function ScrollUp() {
  const [showElement, setShowElement] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 50) {
      setShowElement(true);
    } else {
      setShowElement(false);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <span className={`scrollup ${showElement ? 'show' : ''}`}>
        <Link to='https://wa.me/573027360474/?text=¡Hola!%20Acabo%20de%20visitar%20tu%20página%20web%20y%20estoy%20interesado%20en%20adquirir%20uno%20de%20tus%20servicios.%20¿Podrías%20brindarme%20más%20información%20al%20respecto?' className="scrollup__link">
          <FaWhatsapp className='scrollup__icon' />
        </Link>
        <FaAngleUp className='scrollup__icon' onClick={handleScrollToTop} />
      </span>
    </>
  );
}

export default ScrollUp;