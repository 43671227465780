import React, { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './about.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scrollup from '../components/Scrollup';
import ScrollAnimation from '../components/ScrollAnimation';
import about from '../img/yeifry-paez-desarrollador-front-end.png'
import avatar from '../img/yeifry-paez-desarrollador-front-end-avatar.png'
import html from '../img/HTML5.png'
import css from '../img/css.png'
import js from '../img/js.png'
import wordpress from '../img/wordpress.png'
import figma from '../img/figma.png'
import bootstrap from '../img/bootstrap.png'
import studio from '../img/visual studio code.png'
import elementor from '../img/elementor.png'
import divi from '../img/divi.png'
import mail from '../img/mail-icono.png'

function About() {
    const nuevoTitulo = 'Sobre Mí Desarrollador Front-End | Yeifry Páez';
    const nuevaMetaDescripcion = 'Apasionado por la creación de sitios web y experiencias digitales cautivadoras y funcionales. Impulsado por la pasión de convertir ideas en sitios web exitosos';

    return (
        <>
            <Helmet>
                <title>{nuevoTitulo}</title>
                <meta name="description" content={nuevaMetaDescripcion} />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <Header />
            <main className="mg-main">
                <ScrollAnimation />
                <section className="container yp-a-seccion1">
                    <div className="yp-s1-1">
                        <div className="mg-s1">
                            <h1 className="yp-h1 center-movil">¡Hola! Soy <br /> Yeifry Paez</h1>
                            <p className="yp-lead1 center-movil">Especialista en crear Sitios Web receptivos y dinámicos, que brinden una experiencia atractiva al usuario</p>
                        </div>
                        <div>
                            <Link to='/contacto' reloadDocument className='yp-btn'>Comencemos</Link>
                        </div>
                    </div>
                    <div className="reveal-a">
                        <img src={about} alt="Yeifry Paez Desarrollador Front End" />
                    </div>
                </section>

                <section className="bg-a2">
                    <div className="container yp-a-seccion2">
                        <div className="yp-a2-s1">
                            <h2 className="yp-h2 center-movil">Amor y Constancia</h2>
                            <p className="yp-lead1 center-movil">Creación de sitios web excepcionales que brinden valor, contribución, ayuda y asistencia a los usuarios</p>
                        </div>
                        <div className="yp-a2-s2">
                            <div className="yp-a2-block-1">
                                <img src={avatar} alt="Avatar Yeifry Paez Desarrollador Front End" />
                                <div>
                                    <p>Yeifry Páez</p>
                                    <p>Desarrollador Front-end</p>
                                </div>
                            </div>
                            <div className="text-biogr">
                                <p className="yp-lead1">A parte de mis habilidades técnicas, me considero una persona comprometida, responsable, dedicada y amable. Me apasiona aprender sobre diversos temas, estar al día en cuanto a las actualizaciones tecnológicas. Así como también me considero una persona familiar y disfrutar un buen café en compañía de mis seres queridos</p>
                                <p className="yp-lead2">Chris Gardner. “Soy el tipo de persona que si no sabe una respuesta le diré que no la sé. Pero le apuesto a que sé cómo encontrar la respuesta, y la encontraré”</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-a3">
                    <div className="container yp-a-seccion3">
                        <div className="yp-a3-s1">
                            <h2 className="yp-h2">Herramientas</h2>
                            <p className="yp-lead1">Poseo un amplio conocimiento en múltiples herramientas y diversas tecnologias</p>
                        </div>

                        <div className="yp-a3-s2">
                            <div className="ico-hta"><img src={html} alt="Herramienta HTML" /></div>
                            <div className="ico-hta"><img src={css} alt="Herramienta CSS" /><img src={bootstrap} alt="Herramienta Bootstrap" /></div>
                            <div className="ico-hta"><img src={js} alt="Herramienta JS" /><img src={studio} alt="Herramienta Visual Stuido Code" /><img src={divi} alt="Herramienta Divi" /></div>
                            <div className="ico-hta"><img src={wordpress} alt="Herramienta Wordpress" /><img src={elementor} alt="Herramienta Elementor" /></div>
                            <div className="ico-hta"><img src={figma} alt="Herramienta Figma" /></div>
                        </div>
                    </div>
                </section>

                <section className="container yp-a-seccion4">
                    <div className="yp-a3-s1">
                        <h2 className="yp-h2">Pasos para el Desarrollo</h2>
                        <p className="yp-lead1">Metodologías y marcos para la gestión de proyectos</p>
                    </div>

                    <div className="yp-a4-s1">
                        <div className="col-pasos">
                            <p className="yp-lead1 hover-col">Iniciación<span>1</span></p>
                            <p className="yp-lead2">Se evalúa la viabilidad del proyecto</p>
                        </div>
                        <div className="col-pasos">
                            <p className="yp-lead1 hover-col">Planificación<span>2</span></p>
                            <p className="yp-lead2">Desarrollar el plan del proyecto</p>
                        </div>
                        <div className="col-pasos">
                            <p className="yp-lead1 hover-col">Ejecución<span>3</span></p>
                            <p className="yp-lead2">Elaboracion el proyecto </p>
                        </div>
                        <div className="col-pasos">
                            <p className="yp-lead1 hover-col">Cierre<span>4</span></p>
                            <p className="yp-lead2">Evaluar el éxito del proyecto</p>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div className="yp-a-seccion5">
                        <img src={mail} alt="Enviar correo Electronico" />
                        <div className="yp-a5-s1">
                            <h3 className="yp-h3">¡Contácteme hoy para dar el primer paso hacia el éxito!</h3>
                            <Link to='/contacto' reloadDocument className='yp-btn-2'>Únete para comenzar</Link>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
            <Scrollup />
        </>
    );
};

export default About;