import React,{ Helmet } from 'react-helmet';
import './404.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scrollup from '../components/Scrollup';
import { Link } from 'react-router-dom';
import error from '../img/yeifry-paez-error-en-conexion.jpeg';

const Error404 = () => {
  const nuevoTitulo = 'Página no Encontrada | Yeifry Páez';
  const nuevaMetaDescripcion = 'Oops, parece que te has perdido. No te preocupes, estoy aquí para ayudarte a encontrar el camino correcto. Vuelve a mi página principal y descubre todo lo que tengo para ofrecerte';
  return (
    <>
      <Helmet>
        <title>{nuevoTitulo}</title>
        <meta name="description" content={nuevaMetaDescripcion} />
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      
      <Header />
      <main className="mg-main">

          <section className="container">
              <div className="yp-404">
                  <div>
                      <h1 className="yp-h1">Error 404</h1>
                      <p className="yp-lead1">Esta página no se encuentra disponible</p>
                      <Link to='/' className="yp-btn">Volver al Inicio</Link>
                  </div>
                  <div>
                      <img src={error} alt="Error de Conexion pagina no encontrada error 404" />
                  </div>
              </div>
          </section>

      </main>
      <Footer />
      <Scrollup />
    </>
  );
};

export default Error404;
