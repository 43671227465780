import React, { Helmet } from 'react-helmet';
import './sitio.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scrollup from '../components/Scrollup';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../components/ScrollAnimation';
import Web from '../img/yeifry-paez-estacion-de-trabajo-de-ui.jpeg';
import credibilidad from '../img/yeifry-paez-credibilidad-online.svg'
import presencia from '../img/yeifry-paez-presencia-online.svg'
import alcance from '../img/yeifry-paez-mayor-alcance-en-linea.svg'
import informacion from '../img/yeifry-paez-mostrar-informacion-online.svg'
import disponibilidad from '../img/yeifry-paez-sitio-web-disponible-24-7.svg'
import comunicacion from '../img/yeifry-paez-mejor-comunicacion.svg'
import restaurante from '../img/yeifry-paez-ui-restaurante.png'
import tienda from '../img/yeifry-paez-ui-de-tienda.png'
import comestico from '../img/yeifry-paez-ui-comestico.png'
import construccion from '../img/yeifry-paez-ui-construccion.png'
import valor from '../img/yeifry-paez-valor-de-mi-logo.png'
import responsive from '../img/laptop-ui-de-sitio-web-responsive.png'

function SitieWeb() {
    const nuevoTitulo = 'Convierte visitantes en clientes | Yeifry Páez';
    const nuevaMetaDescripcion = 'Potencia tu negocio con un sitio web impactante y funcional. Diseño web de calidad, desarrollo a medida y estrategias SEO efectivas. ¡Haz crecer tu presencia online!';

    return (
        <>
            <Helmet>
                <title>{nuevoTitulo}</title>
                <meta name="description" content={nuevaMetaDescripcion} />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <Header />
            <main className="mg-main">

                <section className="bg-pagina-web">
                    <ScrollAnimation />
                    <div className="container yp-p-seccion1">
                        <div className="yp-p-s1-1">
                            <div className="mg-s1">
                                <h1 className="yp-h1">Hazte notar con un <span>Sitio Web</span></h1>
                                <p className="yp-lead1">Que refleje tu esencia y conecta con tu audiencia en el mundo digital, a tan solo un clic de distancia</p>
                            </div>
                            <div>
                                <p className="yp-lead2 text-white">01 | Convierte visitantes en Clientes</p>
                            </div>
                        </div>
                        <div className="yp-p-s1-2">
                            <img src={Web} alt="Mesa de trabajo de diseño Web Atractivo" />
                        </div>
                    </div>

                    <div className="container yp-a-seccion4">
                        <div className="yp-a3-s1">
                            <h2 className="yp-h2 text-white">Conecta con tus clientes</h2>
                            <p className="yp-lead1 text-white">Una página web es una herramienta valiosa para mejorar la presencia y el éxito del negocio en línea</p>
                        </div>
                        <div className="yp-p-s2-1">
                            <div className="col-web">
                                <img src={presencia} alt="Ilustracion de como se ve una Presencia en linea" />
                                <div>
                                    <h4 className="yp-h4">Presencia en línea</h4>
                                    <p className="text-body">Es la visibilidad que tiene un negocio en Internet, para que los clientes tengan  acceso con tan solo un clic</p>
                                </div>
                            </div>
                            <div className="col-web">
                                <img src={credibilidad} alt="Ilustracion de como se construye una Credibilidad en linea" />
                                <div>
                                    <h4 className="yp-h4">Credibilidad</h4>
                                    <p className="text-body">Un Sitio Web garantiza y ayuda a una marca a ser confiable, serio y real, a través de la credibilidad</p>
                                </div>
                            </div>
                            <div className="col-web">
                                <img src={alcance} alt="Ilustracion de como tiene un Mayor Alcance un negocio Online" />
                                <div>
                                    <h4 className="yp-h4">Mayor alcance</h4>
                                    <p className="text-body">Un sitio web también puede ayudar a tú marca o negocio a tener un mayor alcance local, nacional o internacional</p>
                                </div>
                            </div>
                            <div className="col-web">
                                <img src={informacion} alt="Ilustracion de Compartir informacion con tus clientes en linea" />
                                <div>
                                    <h4 className="yp-h4">Compartir información</h4>
                                    <p className="text-body">Brindar la información necesaria a los usuarios, es la menera más efectiva a la hora de ofrecer un producto o servicio</p>
                                </div>
                            </div>
                            <div className="col-web">
                                <img src={disponibilidad} alt="Ilustracion de negocio disponible en todo momento" />
                                <div>
                                    <h4 className="yp-h4">Disponibilidad 24/7</h4>
                                    <p className="text-body">Un sitio web está disponible las 24 horas, los 7 días de la semana, garantizando el acceso a la información en cada momento</p>
                                </div>
                            </div>
                            <div className="col-web">
                                <img src={comunicacion} alt="Ilustracion de como un sitio web Mejora la atencion con tus clientes" />
                                <div>
                                    <h4 className="yp-h4">Mejor servicio al cliente</h4>
                                    <p className="text-body">Establecer una buena comunicación con el cliente es la herramienta más poderosa para un sitio web</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Link to='/contacto' className="btn-pagina text-white" reloadDocument>Quiero comenzar</Link>
                        </div>
                    </div>
                </section>

                <section className="container yp-a-seccion4">
                    <div>
                        <h2 className="yp-h2 center-movil">Tú página web es única</h2>
                    </div>
                    <div className="yp-p-s3-2">
                        <div className="col-web-s3 p-s3-1">
                            <h3 className="yp-h3">Diseño</h3>
                            <p className="yp-lead1 md4">Comodidad en la interacción con el página web</p>
                        </div>
                        <div className="col-web-s3 p-s3-2">
                            <h3 className="yp-h3">Fácil navegación </h3>
                            <p className="yp-lead1 md4">Permitir encontrar la información de manera rápida y fácil</p>
                        </div>
                        <div className="col-web-s3 p-s3-3">
                            <h3 className="yp-h3">Carga Rápida</h3>
                            <p className="yp-lead1 md4">Garantiza la satisfacción del usuario y reduce la probabilidad de que abandonen el sitio</p>
                        </div>
                        <div className="col-web-s3 p-s3-4">
                            <h3 className="yp-h3">Posicionamiento</h3>
                            <p className="yp-lead1 md4">Mejora la visibilidad y posicionamiento de una página web en los resultados de busqueda</p>
                        </div>
                        <div className="col-web-s3 p-s3-5">
                            <h3 className="yp-h3">Empatizar</h3>
                            <p className="yp-lead1 md4">Mejora la comprensión del usuario y su satisfacción</p>
                        </div>
                        <div className="col-web-s3 p-s3-6">
                            <h3 className="yp-h3">Seguridad</h3>
                            <p className="yp-lead1 md4">Mantener la confianza de los usuarios y protección de la privacidad</p>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div className="yp-a-seccion4">
                        <div>
                            <h2 className="yp-h2">Prototipos de Diseño</h2>
                        </div>
                        <div className="yp-p-s4-1">
                            <div className="col-web-s4">
                                <p className="yp-h4">Restaurante - <span className="text-body">Lo mejor de tú negocio</span></p>
                                <img src={restaurante} alt="Un pagina indicada para tu restaurante" />
                                <div className="yp-p-s4-block">
                                    <span>Landing page</span>
                                    <Link to='/contacto' className="yp-btn" reloadDocument>Quiero saber más</Link>
                                </div>
                            </div>
                            <div className="col-web-s4">
                                <p className="yp-h4">Electrónicos - <span className="text-body">Llega a más personas</span></p>
                                <img src={tienda} alt="La tienda en linea que necesitas" />
                                <div className="yp-p-s4-block">
                                    <span>E-COMMERCE</span>
                                    <Link to='/contacto' className="yp-btn" reloadDocument>Quiero saber más</Link>
                                </div>
                            </div>
                            <div className="col-web-s4">
                                <p className="yp-h4">Belleza - <span className="text-body">Controla tú agenda de trabajo</span></p>
                                <img src={comestico} alt="Con un sitio Web puedes controlar la agenda de tu negocio" />
                                <div className="yp-p-s4-block">
                                    <span>Web APP</span>
                                    <Link to='/contacto' className="yp-btn" reloadDocument>Quiero saber más</Link>
                                </div>
                            </div>
                            <div className="col-web-s4">
                                <p className="yp-h4">Construcción - <span className="text-body">Muestra tú trabajo</span></p>
                                <img src={construccion} alt="Muestra lo que hace tu negocio con un sitio Web" />
                                <div className="yp-p-s4-block">
                                    <span>Sitio web</span>
                                    <Link to='/contacto' className="yp-btn" reloadDocument>Quiero saber más</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="container yp-p-seccion5">
                    <div>
                        <img src={valor} alt="Valor del logo de Yeifry Paez" />
                    </div>
                    <div className="yp-p-s5-1">
                        <div className="yp-p-s5-block">
                            <div>
                                <p className="yp-h4">+70%</p>
                                <span>Recomienda tener un sitio web</span>
                            </div>
                        </div>
                        <div className="yp-p-s5-block">
                            <div>
                                <p className="yp-h4">64,4%</p>
                                <span>Población mundial buscan en internet</span>
                            </div>
                        </div>
                        <div className="yp-p-s5-block">
                            <div>
                                <p className="yp-h4">25%</p>
                                <span>De ventas estas perdiendo sin un sitio web</span>
                            </div>
                        </div>
                        <div className="yp-p-s5-block">
                            <div>
                                <p className="yp-h4">3-5</p>
                                <span>Personas buscan lo que estas vendiendo en internet </span>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="container">
                    <div className="yp-a-seccion4">
                        <div>
                            <h2 className="yp-h2">Diferencias entre...</h2>
                        </div>
                        <div className="yp-p-s6-1">
                            <div className="yp-p-s6-block">
                                <p className="yp-h3">Agencia</p>
                                <p className="yp-h5">Mayor Costos</p>
                                <p className="yp-h5">Comunicación mas formal y estructurada</p>
                                <p className="yp-h5">Trabajan en varios proyectos al mismo tiempo</p>
                                <p className="yp-h5">Tarda más en respuesta de consulta y solicitud</p>
                                <p className="yp-h5">Tiene mayor cantidad de recursos</p>
                            </div>
                            <div className="yp-p-s6-block">
                                <p className="yp-h3">Freelancer</p>
                                <p className="yp-h5">Costos más flexibles</p>
                                <p className="yp-h5">La comunicación es mas directa y personal </p>
                                <p className="yp-h5">Enfoque en un único proyecto</p>
                                <p className="yp-h5">Mayor agilidad en responder a las necesidades del cliente</p>
                                <p className="yp-h5">Dedicación de forma más intensa al proyecto</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-pagina-s7">
                    <div className="container yp-p-seccion7">
                        <img src={responsive} alt="Laptop con un diseño de un sitio web responsive" />
                        <div className="yp-p-s7-1">
                            <div>
                                <p className="yp-lead1"><span className="yp-h2">Los Freelance</span><br />la ventaja de poder adaptarse rápidamente a las necesidades de sus clientes</p>
                                <Link to='/contacto' className="yp-btn-2" reloadDocument>Contactar Ahora</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
            <Scrollup />
        </>
    );
};

export default SitieWeb;