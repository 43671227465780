import React from 'react';
import './footer.css'
import Icono from './IconoFooter';
import { Link } from 'react-router-dom';
import logoFooter from '../img/logoblanco.png'

function Footer() {

  return (
    <footer className='footer'>
        <div className="container yp-footer">
                <Link to="/" reloadDocument><img src={logoFooter} alt="logo Yeifry Paez" /></Link>            
                <div className="yp-nav-footer">
                  <ul className="nav-footer">
                    <li id="boton"><Link to='/' reloadDocument>Inicio</Link></li>
                    <li id="boton"><Link to='/sobre-mi' reloadDocument>Sobre Mí</Link></li>
                    <li id="boton"><Link to='/sitio-web' reloadDocument>Sitio Web</Link></li>
                    <li id="boton"><Link to='/tienda-linea' reloadDocument>Tienda en Línea</Link></li>
                    <li id="boton"><Link to='/contacto' reloadDocument>Contacto</Link></li>
                  </ul>
                </div>
                <span className="span-footer"></span>
                <div className="yp-f-block">
                    <p className="text-body text-white">© 2023 Yeifry Paez. All rights reserved</p>
                    <Link to='/politica-privacidad' reloadDocument className='text-white'>Política de Privacidad</Link>
                    <Icono />
                </div>
        </div>
    </footer>
  );
};

export default Footer;