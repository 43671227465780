import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icono from './IconoHeader';
import { FaUserLarge } from "react-icons/fa6";
import { FaHouseChimney } from "react-icons/fa6";
import { FaRegWindowMaximize } from "react-icons/fa6";
import { FaStore } from "react-icons/fa6";
import { FaEnvelopeOpenText } from "react-icons/fa6";
import logo from '../img/Logo.png'
import './header.css'

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <header>
      <nav className='menu container'>
        <div>
          <div className='btn'>
            <div className="btnm">
              <div className={isMenuOpen ? "overlay activo" : "overlay"}></div>
              <button className={`btn-menu ${isMenuOpen ? "close" : ""}`} onClick={handleClick}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            <div className={isMenuOpen ? "nav-menu activo" : "nav-menu"}>
              <ul className="navegacion">
                <li id="boton"><span><FaUserLarge /></span><Link to="/" onClick={handleLinkClick}>Inicio</Link></li>
                <li id="boton"><span><FaHouseChimney /></span><Link to="/sobre-mi" onClick={handleLinkClick}>Sobre Mí</Link></li>
                <li id="boton"><span><FaRegWindowMaximize /></span><Link to="/sitio-web" onClick={handleLinkClick}>Sitio Web</Link></li>
                <li id="boton"><span><FaStore /></span><Link to="/tienda-linea" onClick={handleLinkClick}>Tienda en Línea</Link></li>
                <li id="boton"><span><FaEnvelopeOpenText /></span><Link to="/contacto" onClick={handleLinkClick}>Contacto</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className='nav-logo'>
          <Link to="/"><img src={logo} alt="logo Yeifry Paez" /></Link>
        </div>
        <Icono />
      </nav>
    </header>
  );
}

export default Header;