import React,{ Helmet } from 'react-helmet';
import './politica.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scrollup from '../components/Scrollup';

function Politica() {
  const nuevoTitulo = 'Política de privacidad | Yeifry Páez';
  const nuevaMetaDescripcion = 'Protección de datos confiable y conforme a las regulaciones. Garantizo la privacidad y seguridad de tus datos. Descubre cómo garantizo la confidencialidad y cumplimiento normativo en el tratamiento de datos';
    
  return (
    <>
      <Helmet>
        <title>{nuevoTitulo}</title>
        <meta name="description" content={nuevaMetaDescripcion} />
        <meta name="robots" content="noindex, follow"></meta>
      </Helmet>

      <Header />
      <main className="mg-main">

          <div className="container yp-polit">

          <p className="text-body">La protección de sus datos personales es muy importante. Esta política de privacidad explica cómo será tratada su información personal en cumplimiento de las leyes de Colombia para la protección de datos</p>
          <h4 className="yp-h4">Identidad del responsable del tratamiento de datos</h4>
          <p className="text-body">El responsable del tratamiento de sus datos personales es Yeifry Páez Trujillo, con NIT 700326917, con domicilio en Colombia Bogotá DC, teléfono +57 3027360474 y correo electrónico ypaez.025@gmail.com</p>
          <h4 className="yp-h4">Datos personales que serán recopilados son los siguientes</h4>
          <ul>
              <li>Nombre y Apellido</li>
              <li>Dirección de correo electrónicoos</li>
          </ul>
          <h4 className="yp-h4">Finalidades del tratamiento de sus datos personal para los siguientes fines</h4>
          <ul>
              <li>Facilitar la adquisición de servicios</li>
              <li>Envío de información comercial y promociones de productos y servicios</li>
              <li>Atender sus solicitudes y consultas</li>
          </ul>
          <p className="text-body">Su información persona NO es compartida con terceros</p>
          <h4 className="yp-h4">Usted tiene los siguientes derechos sobre sus datos personales</h4>
          <ul>
              <li>Rectificar sus datos personales</li>
              <li>Suprimir sus datos personales</li>
              <li>Limitar el tratamiento de sus datos personales</li>
              <li>Portar sus datos personales a otro responsable</li>
              <li>Oponerse al tratamiento de sus datos personales</li>
          </ul>
          <p className="text-body">Para ejercer estos derechos, puede ponerse en contacto a través de la dirección de correo electrónico </p>
          <ul>
              <li>Plazo de conservación de los datos: sus datos personales serán conservados mientras sea necesario para la finalidad para la que fueron recopilados, salvo que usted solicite su supresión o limitación de los mismos</li>
              <li>Seguridad de los datos personales: se adoptan medidas técnicas y organizativas para proteger sus datos personales contra el acceso no autorizado, la pérdida, el uso indebido o la alteración</li>
              <li>Modificaciones de la política de privacidad: me reservo el derecho a modificar esta política de privacidad en cualquier momento. Si se realizan cambios significativos, le informaremos por correo electrónico o a través de nuestro sitio web</li>
          </ul>
          <p className="text-body">Esta política de privacidad se rige por las leyes Colombianas, incluyendo la Ley Estatutaria 1581 de 2012 y 1266 de 2008 sus decretos reglamentarios, así como cualquier otra ley y regulación aplicable a la protección de datos personales en Colombia</p>
          <p className="text-body">Si tiene alguna pregunta o comentario sobre las política de privacidad, no dude en ponerse en contacto a través de la dirección de correo electrónico ypaez.025@gmail.com</p>
          </div>

      </main>
      <Footer />
      <Scrollup />
    </>
  );
};

export default Politica;