import React, { useEffect, useState, useRef } from 'react';
import './slider.css';
import imgSeccion31 from '../img/yeifry-paez-diseño-ui-ux.jpeg';
import imgSeccion32 from '../img/yeifry-paez-lluvia-de-ideas.jpeg';
import imgSeccion33 from '../img/yeifry-paez-confianza-cliente.jpeg';
import imgSeccion34 from '../img/yeifry-paez-cafe-productividad.jpeg';

function Slider() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1109);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sliderRef = useRef(null);
  const puntosRefs = useRef([]);

  const handlePuntoClick = (posicion) => {
    const operacion = posicion * -25;
    sliderRef.current.style.transform = `translateX(${operacion}%)`;
    setActiveIndex(posicion);
  };

  useEffect(() => {
    if (!isSmallScreen) {
      sliderRef.current.style.transform = 'translateX(0%)';
    }
  }, [isSmallScreen]);

  return (
    <div className="yp-s3-1">
      <div className={`yp-s3-img ${isSmallScreen ? 'slider' : ''}`} ref={sliderRef}>
        <img src={imgSeccion31} alt="Diseño de página UI UX" />
        <img src={imgSeccion32} alt="Lluvia de ideas" />
        <img src={imgSeccion33} alt="Diseño Web que inspira confianza" />
        <img src={imgSeccion34} alt="Un café y un buen diseño" />
      </div>

      <ul className="puntos">
        <li className={`punto ${activeIndex === 0 ? 'activo' : ''}`} ref={(el) => puntosRefs.current[0] = el} onClick={() => handlePuntoClick(0)}></li>
        <li className={`punto ${activeIndex === 1 ? 'activo' : ''}`} ref={(el) => puntosRefs.current[1] = el} onClick={() => handlePuntoClick(1)}></li>
        <li className={`punto ${activeIndex === 2 ? 'activo' : ''}`} ref={(el) => puntosRefs.current[2] = el} onClick={() => handlePuntoClick(2)}></li>
        <li className={`punto ${activeIndex === 3 ? 'activo' : ''}`} ref={(el) => puntosRefs.current[3] = el} onClick={() => handlePuntoClick(3)}></li>
      </ul>
    </div>
  );
}

export default Slider;