import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import About from './pages/About';
import SitieWeb from './pages/SitioWeb';
import TiendaLinea from './pages/TiendaLinea';
import Contacto from './pages/Contacto';
import Politica from './pages/Politica';
import Error404 from './pages/Error404';

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/sobre-mi" element={<About />} />
          <Route path="/sitio-web" element={<SitieWeb />} />
          <Route path="/tienda-linea" element={<TiendaLinea />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/politica-privacidad" element={<Politica />} />
          <Route path="*" element={<Error404 />} />
        </Routes>

      </>
    </Router>
  );
};

export default App;
