import React, { Helmet } from 'react-helmet';
import './index.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scrollup from '../components/Scrollup';
import { Link } from 'react-router-dom';
import SliderImg from '../components/Carrusel';
import ContactForm from '../components/FormHome';
import ScrollAnimation from '../components/ScrollAnimation';
import imgSeccion1 from '../img/yeifry-paez-memoji.png'
import imgSeccion71 from '../img/apple.png'
import imgSeccion72 from '../img/spotify.png'
import imgSeccion73 from '../img/microsoft.png'
import imgSeccion74 from '../img/google.png'
import imgSeccion75 from '../img/tesla.png'
import imgSeccion76 from '../img/meta.png'
import imgSeccion77 from '../img/nvidia.png'
import imgSeccion78 from '../img/amazon.png'
import imgSeccion79 from '../img/tencent.png'

function Index() {
    const nuevoTitulo = 'Potencia tu presencia en línea | Yeifry Páez';
    const nuevaMetaDescripcion = 'Diseño personalizado, SEO optimizado. Sitios web atractivos, rápidos y seguros. Logra una presencia web sólida y atrae tráfico de calidad';

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Helmet>
                <title>{nuevoTitulo}</title>
                <meta name="description" content={nuevaMetaDescripcion} />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <>
            <Header />
                <main className="mg-main">
                <ScrollAnimation />
                    <section className="container yp-seccion1">
                        <div className="yp-s1-1">
                            <div className="mg-s1">
                                <h1 className="yp-h1 yp-titulo">Desarrollador Web</h1>
                                <p className="yp-lead1 center-lead">No esperes más para tener un Site Web o una Tienda en Línea, donde des a conocer tus productos o servicios única y adecuada para tus clientes</p>
                            </div>
                            <div className="btn-posicion">
                                <Link onClick={() => scrollToSection('contactar')} className="yp-btn">Empezar</Link>
                            </div>
                        </div>
                        <div className="img-s1">
                            <img src={imgSeccion1} alt="memoji de Yeifry Páez" className="memoji-home" />
                        </div>
                    </section>

                    <section className="container yp-seccion2">
                        <div className="yp-s2-1">
                            <h2 className="yp-h2">Beneficios de trabajar conmigo</h2>
                        </div>
                        <div className="yp-s2-2">
                            <div className="yp-s2-block">
                                <div className="svg-s2"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M12 4c-4.879 0-9 4.121-9 9s4.121 9 9 9 9-4.121 9-9-4.121-9-9-9zm0 16c-3.794 0-7-3.206-7-7s3.206-7 7-7 7 3.206 7 7-3.206 7-7 7z" /><path d="M13 12V8h-2v6h6v-2zm4.284-8.293 1.412-1.416 3.01 3-1.413 1.417zm-10.586 0-2.99 2.999L2.29 5.294l2.99-3z" /></svg></div>
                                <div className="yp-block-mg">
                                    <h4 className="yp-h4">Entrega a Tiempo</h4>
                                    <p className="text-body">Puntualidad en el plazo acordado, garantizando la satisfacción de obtener los resultados requeridos</p>
                                </div>
                            </div>
                            <div className="yp-s2-block">
                                <div className="svg-s2"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z" /></svg></div>
                                <div className="yp-block-mg">
                                    <h4 className="yp-h4">Garantía de Servicio</h4>
                                    <p className="text-body">Comprometido con el cliente, para brindar la mejor atención a cualquier inquietud y mejora del proyecto</p>
                                </div>
                            </div>
                        </div>
                        <div className="yp-s2-2">
                            <div className="yp-s2-block">
                                <div className="svg-s2"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M5 18v3.766l1.515-.909L11.277 18H16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h1zM4 8h12v8h-5.277L7 18.234V16H4V8z" /><path d="M20 2H8c-1.103 0-2 .897-2 2h12c1.103 0 2 .897 2 2v8c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2z" /></svg></div>
                                <div className="yp-block-mg">
                                    <h4 className="yp-h4">Excelente comunicación</h4>
                                    <p className="text-body">Facilitando el intercambio de ideas y fomentando un proceso más creativo, generando soluciones innovadoras</p>
                                </div>
                            </div>
                            <div className="yp-s2-block">
                                <div className="svg-s2"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z" /></svg></div>
                                <div className="yp-block-mg">
                                    <h4 className="yp-h4">Términos de trabajo flexibles</h4>
                                    <p className="text-body">Cumplimiento de acuerdos y compromisos, para obtener el resultado y satisfacción del cliente</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="container yp-seccion3">
                        <SliderImg />
                        <div className="yp-s3-2">
                            <div>
                                <h3 className="yp-h3">Mucho más que....</h3>
                                <p className="text-body">Un servicio , es una oportunidad de lograr nuevas metas y más alcance</p>
                            </div>
                            <div className="yp-s3-blocks">
                                <div className="yp-s3-block">
                                    <div className="svg-s3"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-3 3h2v2h-2V6zm-3 0h2v2h-2V6zM4 19v-9h16.001l.001 9H4z" /></svg></div>
                                    <div className="yp-block-mg">
                                        <h4 className="yp-h4"><Link to='/sitio-web' reloadDocument>Sitio Web</Link></h4>
                                        <p className="text-body">Es la hora de dar a conocerte y tener mayor alcance</p>
                                    </div>
                                </div>
                                <div className="yp-s3-block">
                                    <div className="svg-s3"><svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path d="M21.999 8a.997.997 0 0 0-.143-.515L19.147 2.97A2.01 2.01 0 0 0 17.433 2H6.565c-.698 0-1.355.372-1.714.971L2.142 7.485A.997.997 0 0 0 1.999 8c0 1.005.386 1.914 1 2.618V20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-5h4v5a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-9.382c.614-.704 1-1.613 1-2.618zm-2.016.251A2.002 2.002 0 0 1 17.999 10c-1.103 0-2-.897-2-2 0-.068-.025-.128-.039-.192l.02-.004L15.219 4h2.214l2.55 4.251zm-9.977-.186L10.818 4h2.361l.813 4.065C13.957 9.138 13.079 10 11.999 10s-1.958-.862-1.993-1.935zM6.565 4h2.214l-.76 3.804.02.004c-.015.064-.04.124-.04.192 0 1.103-.897 2-2 2a2.002 2.002 0 0 1-1.984-1.749L6.565 4zm3.434 12h-4v-3h4v3z" /></svg></div>
                                    <div className="yp-block-mg">
                                        <h4 className="yp-h4"><Link to='/tienda-linea' reloadDocument>Tienda en Línea</Link></h4>
                                        <p className="text-body">Muestra tu catálogo de productos o servicios de forma virtual y así llegar a más personas</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Link onClick={() => scrollToSection('contactar')} className="yp-btn">Comenzar</Link>
                            </div>
                        </div>
                    </section>

                    <section className="bg-s4">
                        <div className="container">
                            <div className="yp-seccion4">
                                <div className="yp-s4-1">
                                    <h2 className="yp-h2">¿Sabías esto?</h2>
                                </div>
                                <div className="yp-s4-2">
                                    <div>
                                        <h3 className="yp-h2">64,4%</h3>
                                        <p className="text-body">De la población mundial se encuentra conectado a internet</p>
                                    </div>
                                    <span className="span-s4"></span>
                                    <div>
                                        <h3 className="yp-h2">9/10</h3>
                                        <p className="text-body">Personas, compra utilizando tanto el canal online como tradicional.</p>
                                    </div>
                                    <span className="span-s4"></span>
                                    <div>
                                        <h3 className="yp-h2">2/4</h3>
                                        <p className="text-body">Horas es el promedio que pasa un usuario en línea</p>
                                    </div>
                                    <span className="span-s4"></span>
                                    <div>
                                        <h3 className="yp-h2">2023</h3>
                                        <p className="text-body">En este año se espera que el ecommerce representará el 21% de las compras</p>
                                    </div>
                                </div>
                                <p className="yp-lead1">Confía en que tu inversión aumentara tus ventas</p>
                            </div>
                        </div>
                    </section>

                    <section className="bg-s5">
                        <div className="container bg-s5-2">
                            <div className="yp-s5-1">
                                <h2 className="yp-h2">Es hora, que esperas</h2>
                                <p className="yp-lead1">Es el momento de tener mayor alcance, para llegar a esos potenciales clientes que buscan tu producto o servicio, no esperes más</p>
                            </div>
                            <div className="yp-s5-2">
                                <Link onClick={() => scrollToSection('contactar')} className="yp-btn-2">Comenzar mi Proyecto</Link>
                            </div>
                        </div>
                    </section>

                    <section className="bg-form-home">
                        <div className="container yp-seccion6" id="contactar">
                            <div className="yp-s6-1">
                                <h3 className="yp-h3">No pierdas más tiempo</h3>
                                <p className="yp-lead1">Contactame y deja tu información, me comunicaré contigo lo más pronto posible.</p>
                            </div>
                            <div className="yp-s6-2">
                                <p>Empieza hoy</p>
                                <ContactForm />
                            </div>
                        </div>
                    </section>

                    <section className="container ">
                        <div className="yp-s7-1">
                            <img src={imgSeccion71} alt="Apple" />
                            <img src={imgSeccion72} alt="Spotify" />
                            <img src={imgSeccion73} alt="Microsoft" />
                            <img src={imgSeccion74} alt="Google" />
                            <img src={imgSeccion75} alt="Tesla" />
                            <img src={imgSeccion76} alt="Meta" />
                            <img src={imgSeccion77} alt="Nvidia" />
                            <img src={imgSeccion78} alt="Amazon" />
                            <img src={imgSeccion79} alt="Tencent" />
                        </div>

                        <div className="yp-s7-2">
                            <h3 className="yp-h3">Recuerda que las empresas que incluyen la tecnología a sus procesos aumentan su productividad, controlan mejor sus procesos y aumenta su competitividad en el mercado</h3>
                            <p className="yp-lead1">Una identidad digital que impacte a tus clientes</p>
                            <Link to='/contacto' className="yp-btn-2" reloadDocument>Quiero una Presencia en línea</Link>
                        </div>
                    </section>

                </main>
                <Footer />
                <Scrollup />
            </>
        </>
    );
};

export default Index;