import { useEffect } from 'react';
import ScrollReveal from 'scrollreveal';

const ScrollAnimation = () => {
  useEffect(() => {
    const srTop = ScrollReveal({
      origin: 'top',
      distance: '20px',
      duration: 500,
      easing: 'linear',
    });

    srTop.reveal('.yp-s1-1, .yp-a2-s1, .yp-p-s1-1, .yp-t-seccion1, .yp-t-seccion5, .yp-c-seccion1', {});
    srTop.reveal('.img-s1, .reveal-a, .yp-p-s1-2', { delay: 200 });
    srTop.reveal('.col-pasos', { interval: 200 });

    const srbottom = ScrollReveal({
      origin: 'bottom',
      distance: '30px',
      duration: 500,
      easing: 'linear',
    });

    srbottom.reveal('.yp-s4-2, .yp-a2-s2', {});
    srbottom.reveal('.yp-s7-1 img, .col-web-s4', { interval: 200 });

    const srleft = ScrollReveal({
      origin: 'left',
      distance: '1rem',
      duration: 500,
      easing: 'linear',
    });

    srleft.reveal('.yp-p-s7-1', {});

    const srRight = ScrollReveal({
      origin: 'right',
      distance: '1rem',
      duration: 500,
      easing: 'linear',
    });

    srRight.reveal('.yp-s2-2, .yp-s3-2, .video-demostracion', {});
  }, []);

  return null;
};

export default ScrollAnimation;