import React,{ Helmet } from 'react-helmet';
import './contacto.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scrollup from '../components/Scrollup';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../components/ScrollAnimation';
import ContactForm from '../components/FormContac';
import Icono from '../components/IconoFooter';
import cohete from '../img/cohete-despegando-impulsando-tus-conexiones.png';

function Contacto() {
  const nuevoTitulo = 'Contáctame para impulsar tu presencia en línea | Yeifry Páez';
  const nuevaMetaDescripcion = 'Si necesitas resolver dudas, recibir orientación o discutir un proyecto. Completa el formulario o llámame para discutir tus proyectos y brindarte soluciones a tus necesidades';
  return (
    <>
      <Helmet>
        <title>{nuevoTitulo}</title>
        <meta name="description" content={nuevaMetaDescripcion} />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <Header />
      <main className="mg-main">
        <ScrollAnimation />
        <section className="bg-contacto">
            
            <div className="yp-c-seccion1 container">
                <div>
                    <h1 className="yp-h1 center-movil">¡No más esperas!</h1>
                    <p className="yp-lead1">Obtén asesoramiento personalizado</p>
                </div>
                
                <div className="yp-c-s1">
                    <div>
                        <p>Número Telefonico</p>
                        <Link to="tel:+573027360474">+57 302 7360474</Link>
                    </div>
                    
                    <div>
                        <p>Perfil Social</p>
                        <Icono />
                    </div>
                </div>
            </div>
        </section>

        <section className="yp-c-seccion2 container">
            <div>
                <h2 className="yp-h2">El mundo está más conectado que nunca</h2>
                <ContactForm />
            </div>
            <div>
                <img src={cohete} alt="Presencia impactante: Despegue vertiginoso, impulsando tus conexiones" />
            </div>
        </section>

        <section className="yp-c-seccion3">
            <h2 className="yp-h2">Hagamos algo increíble Juntos</h2>
        </section>

    </main>
    <Footer />
    <Scrollup />
    </>
  );
};

export default Contacto;