import React from 'react';
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function Icono() {
    return (
        <div className="social-footer">
            <Link to="https://www.instagram.com/ypaez.code/" target="_blank"><FaInstagram /></Link>
            <Link to="https://www.facebook.com/yeifrypaez.code" target="_blank"><FaFacebookF /></Link>
            <Link to="https://www.tiktok.com/@yeifrypaez.code" target="_blank"><FaTiktok /></Link>
            <Link to="https://www.linkedin.com/in/yeifry-paez-a7096b257" target="_blank"><FaLinkedinIn /></Link>
        </div>
    );
  };
  
  export default Icono;