import React, { Helmet } from 'react-helmet';
import './tienda.css'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scrollup from '../components/Scrollup';
import { Link } from 'react-router-dom';
import ScrollAnimation from '../components/ScrollAnimation';
import tienda from '../img/yeifry-paez-como-se-controla-una-tienda-en-linea.png';
import catalogo from '../img/yeifry-paez-muestra-tu-producto.png'
import pasarela from '../img/yeifry-paez-opciones-de-pasarela-de-pago.png'
import inventario from '../img/yeifry-paez-gestion-de-inventario.png'
import envio from '../img/yeifry-paez-gestion-de-envio.png'
import carrito from '../img/yeifry-paez-carrito-de-compras.png'
import pedidos from '../img/yeifry-paez-gestion-de-pedidos.png'
import checkout from '../img/yeifry-paez-checkout.png'
import datos from '../img/yeifry-paez-analisis-de-datos.png'
import tiendaRopa from '../img/yeifry-paez-modelo-de-tienda-de-ropa-online.png'
import video from '../img/video-de-compra-en-una-tienda-en-linea.mp4'
import poster from '../img/demostracion-de-tienda-en-linea.PNG'


function TiendaLinea() {
    const nuevoTitulo = 'Convierte tu pasión en un negocio exitoso | Yeifry Paez';
    const nuevaMetaDescripcion = 'Descubre las ventajas de tener una tienda en línea. Vende las 24 horas, aumenta tus ventas y brinda una experiencia de compra segura. ¡Inicia tu éxito en línea ahora!';

    return (
        <>
            <Helmet>
                <title>{nuevoTitulo}</title>
                <meta name="description" content={nuevaMetaDescripcion} />
                <meta name="robots" content="index, follow" />
            </Helmet>

            <Header />
            <main className="mg-main">
                <ScrollAnimation />
                <section className="container yp-t-seccion1">
                    <div className="yp-t-s1-1">
                        <h1 className="yp-h1">Maximiza el éxito con una <span>Tienda en línea</span></h1>
                        <p className="yp-lead1">Convierte tÚ visión en realidad con una tienda en línea intuitiva y atractiva que refleje la calidad y singularidad de tus productos o servicios</p>
                        <Link to='/contacto' className="yp-btn" reloadDocument>Vender en línea</Link>
                    </div>
                    <div className="yp-t-s1-2">
                        <span className="bg-t-seccion1"></span>
                        <img src={tienda} alt="dashboard de una tienda en linea" className="img-tienda-s1" />
                    </div>
                </section>

                <section className="container yp-t-seccion2">
                    <h2 className="yp-h2">La mejor experiencia de compra</h2>
                    <div className="yp-t-s2">
                        <div>
                            <h4 className="yp-h4">Experiencia de usuario</h4>
                            <p className="text-body">Una buena experiencia de usuario es clave en una tienda en línea, debe estar diseñada de manera atractiva e intuitiva para el usuario con un proceso de compra intuitivo</p>
                        </div>

                        <div>
                            <h4 className="yp-h4">Seguridad</h4>
                            <p className="text-body">Es importante destacar las medidas de seguridad implementadas, como la protección de datos, la autenticación de usuario y la protección de la información</p>
                        </div>

                        <div>
                            <h4 className="yp-h4">Servicio al cliente</h4>
                            <p className="text-body">Contar con canales de atención al cliente eficientes, como chat en vivo, correo electrónico y teléfono, para responder a las dudas y necesidades de los clientes</p>
                        </div>
                    </div>
                </section>

                <section className="container yp-t-seccion3">
                    <div className="yp-t-s3">
                        <h2 className="yp-h2">Todos los recursos que necesitas</h2>
                        <div className="yp-t-s3-block">
                            <img src={catalogo} alt="Muestra como se ve tu producto en una tienda en linea" />
                            <h3 className="yp-h3">Catálogo de productos</h3>
                            <p className="text-body">Completo y organizado para que los clientes puedan navegar fácilmente y encontrar lo que están buscando</p>
                        </div>
                        <div className="yp-t-s3-block">
                            <img src={pasarela} alt="Muestra las opciones que tiene un negocio para recibir pagos en una tienda en linea" />
                            <h3 className="yp-h3">Pasarelas de pago</h3>
                            <p className="text-body">Integrar diferentes pasarelas de pago, para que los clientes puedan elegir su método de pago preferido, como tarjetas de crédito, transferencias bancarias o pagos en línea</p>
                        </div>
                        <div className="yp-t-s3-block">
                            <img src={inventario} alt="Gestion de inventario de una tienda en linea" />
                            <h3 className="yp-h3">Gestión de inventario</h3>
                            <p className="text-body">Permitir a los administradores, administrar el inventario de productos, incluida la disponibilidad, el precio y la descripción</p>
                        </div>
                        <div className="yp-t-s3-block">
                            <img src={envio} alt="Gestion de envio de una tienda en linea" />
                            <h3 className="yp-h3">Gestión de envío</h3>
                            <p className="text-body">Permitir a los administradores gestionar las opciones de envío, incluidos los proveedores de envío, los costos y los tiempos de entrega</p>
                        </div>
                    </div>

                    <div className="yp-t-s3">
                        <div className="yp-t-s3-block">
                            <img src={carrito} alt="Carrito de compra de una tienda en linea" />
                            <h3 className="yp-h3">Carrito de compras</h3>
                            <p className="text-body">Permitir a los clientes agregar o eliminar productos, actualizar la cantidad y calcular el costo total de la compra</p>
                        </div>
                        <div className="yp-t-s3-block">
                            <img src={pedidos} alt="Gestion de pedido de una tienda en linea" />
                            <h3 className="yp-h3">Gestión de pedidos</h3>
                            <p className="text-body">Permitir a los administradores ver y administrar los pedidos, incluidos los detalles del pedido, la información del cliente y el estado del pago y envío</p>
                        </div>
                        <div className="yp-t-s3-block">
                            <img src={checkout} alt="Como realizar el proceso de pago paso a paso en una tienda en linea" />
                            <h3 className="yp-h3">Proceso de checkout</h3>
                            <p className="text-body">El proceso de checkout debe ser sencillo, seguro, y permitir a los clientes revisar su pedido, ingresar la información de envío, facturación y completar el pago</p>
                        </div>
                        <div className="yp-t-s3-block">
                            <img src={datos} alt="Analisas los datos de tu cliente con una tienda en linea" />
                            <h3 className="yp-h3">Análisis y estadísticas</h3>
                            <p className="text-body">Contar con herramientas de estadísticas, informadas, para poder medir el rendimiento de la tienda y tomar decisiones informadas para mejorar la experiencia del cliente y aumentar las ventas</p>
                        </div>
                    </div>
                </section>

                <section className="yp-t-seccion4 text-center">
                    <h5 className="yp-h5">Tienda en Línea</h5>
                    <h2 className="yp-h2">Un proceso sencillo para ti, una experiencia sensacional para tus clientes</h2>
                    <Link to='/contacto' className="yp-btn" reloadDocument>Comenzar</Link>
                </section>

                <section className="container yp-t-seccion5">
                    <div>
                        <h2 className="yp-h2">¿Te gustaría llegar a un público <span>más</span> amplio y aumentar tus ventas?</h2>
                        <Link to='/contacto' className="yp-btn" reloadDocument>Aprovechar</Link>
                    </div>
                    <img src={tiendaRopa} alt="Modelo de una tienda en linea de venta de ropa infantil" />
                </section>

                <section className="container yp-t-seccion6">
                    <h2 className="yp-h2">Optimiza tú gestión</h2>
                    <div className="yp-t-s6">
                        <div>
                            <div className="yp-t-s6-s1">
                                <div className="yp-t-s6-block">
                                    <h4 className="yp-h4">Administra tus ingresos</h4>
                                    <p className="yp-lead1">Es importante llevar un registro preciso de todas las transacciones financieras, desde las ventas hasta los gastos y pueden mantener un registro preciso de sus finanzas y ofrecer a los clientes una experiencia de pago fácil y segura</p>
                                </div>
                            </div>
                            <div className="yp-t-s6-s1">
                                <div className="yp-t-s6-block">
                                    <h4 className="yp-h4">Ejecuta cada pedido</h4>
                                    <p className="yp-lead1">Ejecutar cada pedido de una tienda en línea es un proceso crítico que requiere precisión, cuidado y atención al detalle para garantizar la entrega correcta y puntual del producto</p>
                                </div>
                            </div>
                            <div className="yp-t-s6-s1">
                                <div className="yp-t-s6-block">
                                    <h4 className="yp-h4">Supervisar Operaciones</h4>
                                    <p className="yp-lead1">Esto implica, monitorear todas la actividades y asegurarse que se cumplan los plazos de entrega, actualizar el inventario, gestionar los pagos, y que todo funcione sin inconvenientes</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <video autoPlay muted loop className="video-demostracion" poster={poster}>
                                <source src={video} type="video/mp4" />
                                Tu navegador no admite la etiqueta de video
                            </video>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div className="yp-t-seccion7">
                        <h5 className="yp-h5">Aprovecha la tendencia del Comercio en línea</h5>
                        <h2 className="yp-h2">Recuerda que Amazon es una de las empresas más grande del mundo y se dedica al comercio electrónico</h2>
                    </div>
                </section>

                <section className="yp-t-seccion8 text-center">
                    <h2 className="yp-h2">El acceso a un producto hace que las personas consuman más</h2>
                    <Link to='/contacto' className="yp-btn" reloadDocument>Comenzar Ahora</Link>
                </section>

            </main>
            <Footer />
            <Scrollup />
        </>
    );
};

export default TiendaLinea;